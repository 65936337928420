
@import '../../index.scss';

.navigate-table-row{
    display: flex;
    align-items: center;
}

.navigate-table-row-content{
    width: 100%;
}