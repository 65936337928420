@import "../../index.scss";

.page-layout-wrap {
  padding: 0 0.5em;
  overflow-y: auto;
  line-height: 1.5em;
  position: relative;
  max-height: 100vh;
}

.page-layout-title-wrap {
  margin-top: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  padding-bottom: 0.3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
}

.page-layout-container {
  width: 100%;
  overflow-x: hidden;
}

.page-layout-back-button {
  position: absolute;
  margin-top: 0.2em;
}

.page-layout-title {
  font-size: 1.6em;
  width: 40vw;
  margin-left: 1em;
}

.page-layout-content {
  padding-top: 1em;
}

.page-layout-ending-padding {
  height: 4em;
}
