@import "../../index.scss";

.chord-graph-main-wrap {
  padding: 0.5em;
  width: fit-content;
  overflow: hidden;
  border-radius: 10%;
}

.chord-graph-main-wrap-minimal {
  display: inline-block;
  padding: 0em;
  border-radius: 0;
  margin: 0.5em 1em;

  .chord-scheme-main-wrap {
    border-radius: 0;
  }

  .chord-graph-chord-label {
    background: transparent;
  }
}

.chord-graph-chord-label {
  text-align: center;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.8);
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin: 0 0.15em;
}

.chord-graph-chord-scheme {
  padding: 0.1em;
}

.chord-scheme-main-wrap {
  position: relative;
  background: white;
  width: 16em;
  height: 13em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.chord-scheme-main-wrap-minimal {
  width: 10em;
}

.chord-scheme-notes-wrap {
  position: relative;
  margin-left: 2em;
}

.chord-scheme-note {
  position: absolute;
  background: black;
  color: white;
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 0.9em;
}

.chord-scheme-background {
  position: relative;
  margin-left: 2em;
}

.chord-scheme-fit-angle {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.4em;
  height: 13em;
}

.vertical-line {
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  margin-left: 1em;
  display: inline-block;
}

.horiz-line {
  height: 3px;
  margin-left: 1em;
  background: rgba(0, 0, 0, 0.3);
  margin-top: 2.5em;
}

.chords-scheme-barre-wrap {
  display: flex;
  margin-top: 0.8em;
  margin-left: 0.5em;
  position: absolute;
}

.chords-scheme-barre-bar {
  background: black;
  width: 5.1em;
  height: 0.7em;
}

.chords-scheme-barre-end {
  background: black;
  width: 1.5em;
  height: 0.7em;
  border-radius: 50%;
  margin-right: -0.7em;
}

.chords-scheme-barre-label {
  position: absolute;
  margin-left: -1.6em;
  margin-top: -0.27em;
  font-weight: 600;
  color: black;
  font-family: times, Arial, Helvetica, sans-serif;
}

.chord-scheme-note-voices {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.2em 0.9em;
  padding-right: 0.5em;
  background: white;
  margin-top: 15.3em;
  font-size: 0.7em;
  width: 9.4em;
  color: rgba(0, 0, 0, 0.5);
}

.chord-scheme-hand-wrap {
  position: absolute;
  height: 11em;
  margin-top: 1.95em;
  margin-left: 5.2em;
}

.chord-scheme-note-voice {
  display: inline-block;
  margin-right: 0.3em;
}
