
@import '../../index.scss';

.material-button-style-enforcer{
    padding: 0;
    width: fit-content;
    overflow: hidden;
    border-radius: 3px;

    display: inline-block;

    button{
        width: 100%;
        color: inherit;  
        border-color: inherit;
        background: inherit;
    }
    button:hover{
        border-color: inherit;
    }
}

.material-button-style-enforcer-icon-button{
    border: 1px solid;
}