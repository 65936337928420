
@import '../../index.scss';

.liturgy-screen-form-wrap{
    background-color: rgba(255,255,255,0.5);
    margin-top: 1em;
    padding: 1em;
    border: 1px solid rgba(0,0,0,0.2);
}

.liturgy-screen-form-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}

.liturgy-screen-spinner-wrap{
    display: flex;
    justify-content: space-around;
}