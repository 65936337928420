@import "../../index.scss";

.index-page-letter-wrap {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.2);
}

.index-page-song-wrap {
  display: flex;
  align-items: center;
  padding: 0.2em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  gap: 1em;
}
.index-page-song-wrap:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.index-page-title-label {
  font-size: 0.8em;
  max-width: 40%;
}
.index-page-color-bar {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.index-page-video-icon {
  height: 1em;
  width: 20px;
}

.index-page-semantic-icon {
  height: 2em;
}
.center-index-spinner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4em;
}

.index-inputs-inrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    width: 45%;
  }
}

.toggle-button-group-on-index {
  button {
    padding: 0.2em 1em;
    font-size: 0.7em;
  }
}

@media screen and (max-width: $mobile-width) {
  .toggle-button-group-on-index {
    display: block !important;
    button {
      min-width: 50%;
      border-radius: 4px !important;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
  .index-inputs-inrow {
    flex-direction: column;
    gap: 0.5em;
    span {
      width: 100%;
    }
  }
}
