
@import '../../index.scss';

.song-view-center-spinner{
    display: flex;
    margin-top: 20vh;
    justify-content: space-around;
}

.song-view-screen-spinner-wrap {
    position: fixed;
    background: rgba(255, 255, 255, 0.54);
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 2em;
    font-weight: 600;
}