
@import '../../index.scss';

.editor-block-input-wrap{
    width: 40%;
    margin-right: 1em;
    display: inline-block;
    margin-top: 0.5em;
}

.editor-block-select-wrap{
    width: 40%;
    margin-right: 1em;
    display: inline-block;
    margin-top: 1em;

    .MuiInputLabel-root{
        margin-left: -0.8em;
    }
}

.editor-block-line-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editor-block-lines-wrap{
    padding: 1em;
    border: 1px solid rgba(0,0,0,0.2);
    margin: 1em 0;
    background: linear-gradient(135deg, rgb(241, 241, 241), rgb(230, 230, 230));
}

.editor-block-line-wrap{
    margin-top: 1em;
    padding-top: 0.5em;
    border-top: 2px dashed rgba(0,0,0,0.2);
}

.editor-block-item-wrap{
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 0.2em;
    padding: 1em;
    margin: 1em 0;
    position: relative;
    box-shadow: 0 0 1em 0 rgba(0,0,0,0.2);
    border-radius: 0.5em;
}

.editor-block-line-text{
    margin-top: 0.5em;
    input{
        font-family: monospace;
    }
}

.editor-block-line-notes{
    input{
        font-family: monospace;
        color: $song-red;
    }
}

.editor-block-item-spaced-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editor-block-number {
    position: absolute;
    margin-top: -0.8em;
    font-size: 0.8em;
}