
@import '../../index.scss';

.slide-show-main-wrap{
    background: black;
    padding: 0.5em;
    margin: 0.3em;
}

.slide-show-image-caption{
    color: white;
}

.slide-show-image-img{
    max-height: 100%;
    width: 100%;
}