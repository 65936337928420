.block-speedometer-wrap {
  margin-top: 0.2em;
  button {
    width: 20px;
    min-width: 20px;
    height: 1em;
  }
  .scroll-command-speed-wrap {
    padding: 0;
    width: 20px;
    min-width: 20px;
    border-radius: 0;
    font-size: 0.7em;
  }
}
