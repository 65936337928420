
@import '../../index.scss';

.guitar-intro-screen-spacer{
    padding: 0 1em;

    img{
        position: relative;
        top: 0.2em;
        width: 1em;
    }
}

.guitar-intro-screen-center-image{
    text-align: center;
    margin: 1em;
    img{
        box-shadow: 0 0 1em 0.2em rgba(0,0,0,0.1);
        border: 2px solid black;
        border-radius: 1em;
        padding: 1em;
        margin: 1em;
        max-width: 50vw;
        background-color: white;
    }
}

.guitar-intro-screen-white-cell{
    background-color: white;
    padding: 0.3em 1em;
    border: 1px solid rgba(0,0,0,0.3);
    margin: 0.1em;
    text-align: center;
    min-width: 8em;
}

.guitar-intro-screen-table-wrap {
    margin: 1em;
    overflow: auto;
    max-width: 70vw;
    max-height: 25em;
}

.guitar-intro-screen-table-wrap-inner{
    display: block;
    margin: 0 auto;
    width: fit-content;
}

.guitar-intro-centered-chord-graph-wrap{
    margin: 0 auto;
    width: fit-content;
}

.guitar-intro-centered-chord-graph{
    transform: scale(0.8);
    display: inline-block;
}

.guitar-intro-iframe-wrap{
    margin: 1em auto;
    overflow: hidden;
    background-color: black;
    border: 2px solid;
    width: 60vw;
    min-width: 17em;
    max-width: 450px;
    max-height: 350px;
    height: 35vw;
    border-radius: 1em;
}