@import "./index.scss";

div {
  transition: background-color 0.25s ease-out;
}

.app-main-wrap {
  padding: 0;
}

.app-page-wrap {
  display: flex;
  width: 100%;
}

.madonnina-overlay {
  position: fixed;
  bottom: 0em;
  right: 0em;
  opacity: 0.2;
}

.madonnina-necoat-overlay {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1em 0 gold;
}

.breadCrumb {
  text-align: center;
  min-width: 20em;
  font-weight: 600;
  padding: 0.4em;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgb(116, 237, 131);
  border: 1px solid rgb(10, 150, 20);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 0.5em 0.2em;
  border-radius: 0.5em;
  opacity: 1;
  position: fixed;
  z-index: 100;
  bottom: 1em;
  right: 1em;
  transition: opacity 700ms linear 0s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
