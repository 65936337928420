
@import './variables.scss';
@import './palettes.scss';

body {
  margin: 0;
  font-family: Arial;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.centered-evident-content{
  margin: 1em 0;
  padding: 0.2em 1em;
  background-color: rgba(0,0,0,0.1);
  text-align: center;
}

.top-space{
  margin-top: 1.2em;
}

$song-red: rgba(186, 23, 23, 0.798);
$mobile-width: 800px;

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}