
@import '../../index.scss';

.notes-input-suggestion-button-1{
    display: inline-block;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 0.1em 0.3em;
    font-size: 0.6em;
    width: 3em;
    cursor: pointer;
    text-align: center;
    background-color: white;
}
.notes-input-suggestion-button-1:hover{
    background-color: rgba(0,0,0,0.2);
}

.notes-input-suggestion-button-2{
    display: inline-block;
    border: 1px solid rgba(0,0,0,0.1);
    padding: 0.1em 0.3em;
    font-size: 0.6em;
    width: 3em;
    cursor: pointer;
    text-align: center;
    background-color: white;
}
.notes-input-suggestion-button-2:hover{
    background-color: rgba(0,0,0,0.1);
}



.notes-input-separator{
    height: 0.2em;
    background-color: rgba(0,0,0,0.2);
}

.notes-input-suggestion-wrap{
    width: fit-content;
    margin-bottom: 1em;
}

.notes-input-suggestion-row{
    display: flex;
}

.notes-input-suggestion-link{
    font-size: 1.4em;
    padding: 0.2em 0.5em;
    border: 1px solid;
    border-radius: 0.2em;
    margin-bottom: 1em;
}

.disabled-item{
    background-color: transparent !important;
    opacity: 0.5;
    cursor: unset;
}