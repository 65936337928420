
@import '../../index.scss';


.reset-password-screen-input-wrap{
    max-width: 400px;
    margin: 2em auto;
    display: flex;
    justify-content: space-around;
}

.reset-password-screen-link-wrap{
    margin: 2em auto;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
}