@import "../../index.scss";

.song-render-main-wrap {
  font-size: 1em;
}

.song-render-title {
  font-size: 1.5em;
  display: inline-block;
  color: $song-red;
  line-height: 1.2em;
  margin-right: 1em;
}
.song-render-plus-align {
  padding: 0.3em;
  font-size: 0.9em;
  position: relative;
  left: -0.15em;
}
.song-render-title-line {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.song-render-subtitle {
  color: $song-red;
  display: inline-block;
  font-size: 0.7em;
  opacity: 0.8;
}

.song-render-color-bar {
  margin-top: 0.5em;
  border: 1px solid;
  padding: 0.2em 1em;
  max-width: 70vw;
}

.song-render-barre {
  margin-top: 0.5em;
  font-size: 1.2em;
  color: $song-red;
}

.song-render-blocks-wrapper {
  padding-top: 1em;
}

.song-render-block-wrap-outer {
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.3em;
  margin-bottom: 1em;
  padding-right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.7), transparent);
}

.song-render-block-wrap {
  display: flex;
}

.song-render-speaker-label {
  margin-right: 0.5em;
  font-size: 1.2em;
  font-weight: 600;
}

.song-render-lines-wrap {
  margin-left: 0.5em;
}

.song-render-lines-outer-wrap {
  display: flex;
  align-items: center;
  max-width: 60vw;
}

.song-render-message {
  border-left: 2px solid rgba(0, 0, 0, 0.05);
  padding-left: 1em;
  display: flex;
  align-items: center;
  white-space: pre;
}

.song-render-lines {
  white-space: pre;
  font-family: monospace;
}

.song-render-altnotes {
  display: flex;
  margin: 0.5em 0;
  color: $song-red;
}

.song-render-notes {
  min-height: 1.5em;
  color: $song-red;
  font-weight: 600;
  display: flex;
}

.song-render-text {
  min-height: 1.5em;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.11);
  display: flex;
}

.song-util-message {
  font-size: 1.1em;
  margin: 1em;
  opacity: 0.8;
  font-style: italic;
}

.song-red-util-message {
  font-size: 1.1em;
  margin: 1em;
  font-weight: 600;
  color: $song-red;
}

.red-ast-popup-message {
  color: $song-red;
  font-weight: 600;
  font-size: 2em;
}

@media screen and (max-width: $mobile-width) {
  .song-render-speaker-label {
    font-size: 1em;
    margin-right: 0;
    min-width: 1.2em;
    width: 1.2em;
    line-break: anywhere;
  }
  .song-render-lines-wrap {
    margin-left: 0;
  }
}
