@import "../../index.scss";

.note-iteractive-label-wrap {
  position: relative;
}

.note-iteractive-label-graph-wrap {
  position: absolute;
  font-size: 1rem;
  z-index: 1;
}

.note-iteractive-label-text {
  position: relative;
  cursor: pointer;
  padding: 0em 0.2em;
  margin-left: -0.1em;
  border-radius: 0.2em;
  transition: background-color 0.25s ease-out;
}

.note-iteractive-label-text:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.note-iteractive-label-graph-wrap-shifter {
  position: absolute;
}

@media screen and (max-width: $mobile-width) {
  .note-iteractive-label-graph-wrap {
    position: fixed;
    left: 20%;
    bottom: 1em;
  }
}
