@import "../../index.scss";

.favorite-page-song-wrap {
  padding: 0.2em 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.favorite-page-song-wrap:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.favorite-page-song-row {
  display: flex;
  align-items: center;
}

.favorite-page-color-bar {
  width: 20px;
  height: 20px;
  margin: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  min-width: 20px;
}

.favorite-page-semantic-icon {
  height: 2em;
  margin-right: 1em;
}

.favorite-page-spaced-button {
  margin-right: 1em;
  min-width: 2.8em;
}

.favorite-page-wide-title {
  display: block;
}

.favorite-page-short-title {
  display: none;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.2em 0.5em;
}

@media screen and (max-width: $mobile-width) {
  .favorite-page-short-title {
    display: block;
  }
  .favorite-page-wide-title {
    display: none;
  }
  .favorite-page-song-wrap {
    button {
      padding: 0;
    }
  }
}
