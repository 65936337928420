
@import '../../index.scss';

.accordion-main-wrap{
    padding: 0;
}

.accordion-title-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 1em;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
}

.accordion-title{
    font-weight: 600;
    font-size: 1.3em;
}

.accordion-title-chevron{
    position: relative;
    top: 0.2em;
    transition: top 0.25s ease-out, transform 0.25s ease-out;
}

.accordion-title-chevron-rotated{
    top: -0.2em;
    transform: rotate(-180deg);
}

.accordion-content{
    overflow: hidden;
    max-height: 0;
    background: rgba(0,0,0,0.1);
    transition: max-height 0.25s ease-out;
}

.accordion-content-inner{
    padding: 1em;
}