
@import '../../index.scss';


.modal-dialog-title{
    min-width: 15em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px solid rgba(0,0,0,0.1);
}

.modal-dialog-close-icon{
    height: 24px;
    margin-left: 2em;
}