
@import '../../index.scss';

.home-app-name{
    font-size: 1.1em;
    text-decoration: underline;
    font-style: italic;
    display: inline-block;
}

.contact-banner-section{
    background: rgba(0,0,0,0.05);
    padding: 1em;
}

.guide-screen-voice{
    margin-left: 1em;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 0.3em 1em;
}