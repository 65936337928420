
@import '../../index.scss';

.editor-screen-input-wrap{
    width: 40%;
    margin-right: 1em;
    display: inline-block;
    margin-top: 0.5em;
}

.editor-screen-select-wrap{
    width: 40%;
    margin-right: 1em;
    display: inline-block;
    margin-top: 1em;

    .MuiInputLabel-root{
        margin-left: -0.8em;
    }
}

.editor-page-button-on-right{
    display: flex;
    flex-direction: row-reverse;
}

.editor-screen-on-row-items{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editor-screen-inline-screen{
    display: inline-block;
    width: 1em;
}