
@import '../../index.scss';


.guide-interactive-song-block-wrap{
    width: 70vw;
    margin: 1em auto;
}

.guide-interactive-song-center-content{
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: space-around;
}