
@import '../../index.scss';

.ast-with-popup-wrap{
    position: relative;
}

.ast-with-popup-ast{
    color: $song-red;
}

.ast-with-popup-popup{
    position: absolute;
    border: 1px solid;
    background: white;
    padding: 1em;
    font-size: 0.9em;
    min-width: 10em;
    margin-top: -5em;
    margin-left: -5em;
    animation: fadein 0.25s ease-out;
}

