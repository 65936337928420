
@import '../../index.scss';


.not-found-main-wrap{
    text-align: center;
}


.not-found-code{
    font-size: 6em;
    font-weight: 600;
    margin-top: 1em;
}

.not-found-label{
    font-size: 1.2em;
}

.not-found-face-wrap{
    height: 7em;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    margin-top: 4em;
}

.not-found-face{
    position: absolute;
    font-size: 4em;
    transform: rotate(90deg);
}