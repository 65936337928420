
@import '../../index.scss';

.tune-screen-centered-content{
    text-align: center;
    margin-top: 1em;
}

.tune-screen-guitar-image{
    height: 20em;
}