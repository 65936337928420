
@import '../../index.scss';

.options-screen-hint-label{
    opacity: 0.7;
    font-size: 0.9em;
    margin-top: -0.2em;
    margin-bottom: 1.5em;
}

.options-screen-spinner{
    height: 20em;
    display: flex;
    align-items: center;
    justify-content: space-around;
}