@import "../../index.scss";

.nav-menu-main-wrap {
  height: 100vh;
  width: 12em;
  border-right: 2px solid;
  background: $background-nav-primary;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.nav-menu-section-wrap {
  margin-top: 1em;
  user-select: none;
}

.nav-menu-section-title {
  background: rgba(0, 0, 0, 0.2);
  font-weight: 600;
  padding: 0.3em 0.7em;
  display: flex;
  align-items: center;
}

.nav-menu-section-content {
  overflow: hidden;
  max-height: 15em;
  transition: all 0.25s ease-out;
}

.nav-menu-section-voice-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  padding: 0.2em 0;
  width: 100%;
}

.nav-menu-section-voice-wrap-icon {
  margin: 0 1em;
}

.nav-menu-section-voice-wrap-label {
  opacity: 0.8;
  font-size: 1.4em;
  margin-left: 0.1em;
  white-space: nowrap;
}

.nav-menu-section-title-chevron {
  margin-right: 1em;
  position: relative;
  transition: top 0.25s ease-out, transform 0.25s ease-out;
}

.nav-menu-section-title-chevron-rotated {
  top: -0.1em;
  transform: rotate(-180deg);
}

.nav-menu-accordion-label {
  margin-left: 0.5em;
}

@media screen and (max-width: $mobile-width) {
  .nav-menu-main-wrap {
    width: 3em;
  }
}
