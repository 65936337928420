@import "../../index.scss";

.command-bar-wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 5;
}

.command-bar-wrap-inner {
  display: flex;
  align-items: center;
  overflow-x: auto;
  max-width: calc(100vw - 110px);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.command-bar-button {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 0 1em;
  margin: 0.6em 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.hide-on-small-screens {
}

@media screen and (max-width: $mobile-width) {
  .hide-on-small-screens {
    display: none;
  }
}
