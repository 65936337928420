
.theme-desert{
  .base_page_background_color{
    background: rgb(253, 250, 244);
  }
  .base_left_menu_background_color{
    background: rgb(220, 195, 164);
  }
  .base_filter_menu_background_color{
    background: rgb(255, 241, 200);
  }
  .toolbar_background_color{
    background: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 10%, rgb(255, 241, 200) 60%, rgba(0, 0, 0, 0.1));
  }
  .command_bar_background{
    background: linear-gradient(180deg, #f2bfbf 0%, #dab99c 10%, #eccc91 100%);
  }
  .base_border_color{
    border-color: rgb(140, 108, 96);
  }
  .spinner_color{
    color: rgb(217, 177, 104);
  }
  .page_title_color{
    color: rgb(103, 92, 70);
  }
  .dark_text_color{
    color: rgb(117, 82, 46);
  }
  .text_color{
    color: rgb(120, 100, 39);
  }
  .box-shadow{
    box-shadow: 0 0 1em 0 rgba(200, 107, 48, 0.6);
  }
  .button-background{
    background: linear-gradient(90deg, rgb(242,191,191) 0%, rgb(218,185,156) 10%, rgb(236,204,145) 100%);
  }
  .plate_background{    
    background: linear-gradient(45deg, rgb(203, 171, 108) 0%, rgb(225, 219, 177) 33%, rgb(203, 171, 108) 100%);
  }
}


.theme-cold{
  .base_page_background_color{
    background: rgb(212, 233, 239);
  }
  .base_left_menu_background_color{
    background: rgb(164, 205, 220);
  }
  .base_filter_menu_background_color{
    background: rgb(55, 135, 167);
  }
  .toolbar_background_color{
    background: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 10%, rgb(200, 225, 255) 60%, rgba(0, 0, 0, 0.1));
  }
  .command_bar_background{
    background: linear-gradient(180deg, #bfe2f2 0%, #9cc1da 10%, #91b8ec 100%);
  }
  .base_border_color{
    border-color: rgb(96, 124, 140);
  }
  .spinner_color{
    color: rgb(104, 185, 217);
  }
  .page_title_color{
    color: rgb(70, 91, 103);
  }
  .dark_text_color{
    color: rgb(46, 92, 117);
  }
  .text_color{
    color: rgb(39, 103, 120);
  }
  .box-shadow{
    box-shadow: 0 0 1em 0 rgba(48, 91, 200, 0.6);
  }
  .button-background{
    background: linear-gradient(90deg, rgb(191, 221, 242) 0%, rgb(156, 184, 218) 10%, rgb(145, 201, 236) 100%);
  }
  .plate_background{    
    background: linear-gradient(45deg, rgb(108, 179, 203) 0%, rgb(177, 203, 225) 33%, rgb(108, 160, 203) 100%);
  }
}



.theme-cinder{
  .base_page_background_color{
    background: rgb(247, 247, 247);
  }
  .base_left_menu_background_color{
    background: rgb(223, 223, 223);
  }
  .base_filter_menu_background_color{
    background: rgb(255, 255, 255);
  }
  .toolbar_background_color{
    background: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 10%, rgb(249, 249, 249) 60%, rgba(0, 0, 0, 0.1));
  }
  .command_bar_background{
    background: linear-gradient(180deg, #f4f4f4 0%, #d7d7d7 10%, #ebebeb 100%);
  }
  .base_border_color{
    border-color: rgb(134, 134, 134);
  }
  .spinner_color{
    color: rgb(219, 219, 219);
  }
  .page_title_color{
    color: rgb(94, 94, 94);
  }
  .dark_text_color{
    color: rgb(108, 108, 108);
  }
  .text_color{
    color: rgb(108, 108, 108);
  }
  .box-shadow{
    box-shadow: 0 0 1em 0 rgba(193, 193, 193, 0.6);
  }
  .button-background{
    background: linear-gradient(90deg, rgb(240, 240, 240) 0%, rgb(216, 216, 216) 10%, rgb(234, 234, 234) 100%);
  }
  .plate_background{    
    background: linear-gradient(45deg, rgb(201, 201, 201) 0%, rgb(218, 218, 218) 33%, rgb(199, 199, 199) 100%);
  }
}


.theme-apple{
  .base_page_background_color{
    background: rgb(244, 253, 244);
  }
  .base_left_menu_background_color{
    background: rgb(178, 214, 183);
  }
  .base_filter_menu_background_color{
    background: rgb(200, 255, 215);
  }
  .toolbar_background_color{
    background: linear-gradient(to top right, rgba(0, 0, 0, 0.2) 10%, rgb(200, 255, 203) 60%, rgba(0, 0, 0, 0.1));
  }
  .command_bar_background{
    background: linear-gradient(180deg, #bff2c8 0%, #9cdaad 10%, #91eca3 100%);
  }
  .base_border_color{
    border-color: rgb(96, 140, 97);
  }
  .spinner_color{
    color: rgb(121, 217, 104);
  }
  .page_title_color{
    color: rgb(70, 103, 70);
  }
  .dark_text_color{
    color: rgb(46, 117, 55);
  }
  .text_color{
    color: rgb(39, 120, 43);
  }
  .box-shadow{
    box-shadow: 0 0 1em 0 rgba(97, 197, 104, 0.6);
  }
  .button-background{
    background: linear-gradient(90deg, rgb(191, 242, 196) 0%, rgb(155, 200, 162) 10%, rgb(167, 224, 170) 100%);
  }
  .plate_background{    
    background: linear-gradient(45deg, rgb(111, 203, 108) 0%, rgb(177, 225, 182) 33%, rgb(108, 203, 121) 100%);
  }
}
