
@import '../../index.scss';


.search-song-input-wrap{
    position: relative;
    margin-top: 0.5em;
}

.search-song-input-results{
    position: absolute;
    background: white;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 0.5em;
    z-index: 10;
    max-height: 15em;
    overflow-y: auto;
    box-shadow: 0 0 1em 0 rgba(0,0,0,0.2);
}

.search-song-input-result{
    border-bottom: 1px solid rgba(0,0,0,0.2);
    cursor: pointer;
    padding: 0.2em 1em;
    min-width: 20em;
}

.search-song-input-result:hover{
    background-color: rgba(0,0,0,0.1);
}

.search-song-input-selected-link{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(0,0,0,0.1);
    padding: 0.2em 1em;
    margin-top: 1.5em;
}

.search-song-input-selected-link-label{
    color: rgb(50, 50, 230);
    cursor: pointer;
}
.search-song-input-selected-link-label:hover{
    text-decoration: underline;
}

.search-song-input-selected-link-remove{
    cursor: pointer;
    display: flex;
}
.search-song-input-selected-link-remove:hover{
    background-color: rgba(0,0,0,0.1);
}