
@import '../../index.scss';

.message-banner-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 0.5em;
    margin: 0.5em 0;
}

.message-banner-flex-row{
    display: flex;
    align-items: center;
}

.message-banner-icon{
    margin-right: 0.5em;
    position: relative;
    top: 0.15em;
}

.message-banner-close-button{
    transition: background-color 0.25s ease-out;
    height: 1.5em;
}
.message-banner-close-button:hover{
    background-color: rgba(0,0,0,.1);
}

.message-banner-wrap-warning{
    background-color: rgb(255, 255, 161);
    color: #2e2e09;
}

.message-banner-wrap-error{
    background-color: rgb(255, 139, 139);
    color: #3d140f;
}

.message-banner-wrap-info{
    background-color: rgb(219, 219, 219);
    color: #2c2a2a;
}

.message-banner-wrap-success{
    background-color: rgb(153, 255, 153);
    color: #113c0c;
}