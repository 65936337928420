
@import '../../index.scss';

.how-to-download-centered-image-wrap{
    background: black;
    text-align: center;
    overflow: auto;
}

.how-to-download-centered-image{
    max-height: 30em;
    max-width: 65vw;
    object-fit: cover;
}