
@import '../../index.scss';

.scroll-commands-main-wrap {
    position: fixed;
    z-index: 10;
    top: 35vh;
    right: 1em;
    display: flex;
    flex-direction: column;
}

.scroll-command-speed-wrap {
    margin: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0.5em 0em;
    text-align: center;
    border-radius: 0.2em;
}