
@import '../../index.scss';

.profile-screen-chart-separator{
    margin-top: 2em;
    margin-bottom: 2em;
    height: 2px;
    background-color: rgba(0,0,0,0.3);
}

.profile-screen-mui-input-wrap-select{
    margin: 1em 0;
}