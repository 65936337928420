@import "../../index.scss";

.video-box-main-wrap {
  position: fixed;
  bottom: 2em;
  right: 2em;
  padding: 0.3em;
  background-color: white;
  z-index: 100;
}

.video-box-toolbar {
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 600;
  color: rgba(0, 0, 0, 0.7) !important;
}

.video-box-iframe {
  border: none;
}

.video-box-no-source {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  height: 150px;
  padding: 0 2em;
}

.video-box-toolbar-close-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.video-box-toolbar-close-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
