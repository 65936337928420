
@import '../../index.scss';

.titled-page-section-wrap{
    margin-top: 2em;
    padding: 0.5em 0;
    border-top: 2px dashed;
}

.titled-page-section-title{
    font-weight: 600;
    margin-top: 0.4em;
    font-size: 1.1em;
}

.titled-page-section-content{
    margin-top: 1em;
}